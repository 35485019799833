import React, { useContext, useEffect, useState } from "react";
import { _Table } from "../../../../../components/Table";
import { dashboardContext } from "../../../Dashboard";
import { addValueToObject } from "../../../../../utils/addValueToObject";
import { toast } from "react-toastify";
import { preventNegativeValues } from "../../../../../utils/preventNegativeValues";
import { preventPasteNegative } from "../../../../../utils/preventPasteNegative";
import Modal from "../../../../../components/Modal/Modal";
import Select from "react-select";
import TaxInvoiceTemplate, {
  amountToWords,
  monthsName,
} from "../CurrentCargeTab/TaxInvoiceTemplate";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_COMMISSION_DETAILS,
  GET_INVOICES,
  REQUEST_INVOICE,
  UPLOAD_INVOICE,
} from "../../../../../Qurries";
import { CustomDropdownIndicator } from "../../../Settlement/Settlement";
import {
  getDateRange,
  getPreviousMonthYear,
} from "../../../../../utils/getStartAndEndOfMonth";
import { isDisabled } from "@testing-library/user-event/dist/utils";
function SelectCommission({ setOpen, invoiceDates }: any) {
  const { transactionReport, commissionReport } = useContext(dashboardContext);
  const [monthlyCommission, setMonthlyCommission] = useState<any>([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState<any>(null);
  const [invoiceDetails, setInvoiceDetails] = useState<any>({});
  const [uploadInvoice, setUploadInvoice] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [hsnNumber, setHsnNumber] = useState("");
  const [commissionDetails, setCommissionDetails] = useState<any>([]);
  const [selectedYear, setSelectedYear] = useState<any>({
    label: getPreviousMonthYear().year.toString(),
    value: getPreviousMonthYear().year.toString(),
  });

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [selectedMonth, setSelectedMonth] = useState<any>({
    label: getPreviousMonthYear().month.toString(),
    value: getPreviousMonthYear().month.toString(),
  });
  const {
    data: getCommission,
    loading: commissionLoading,
    refetch,
  } = useQuery(GET_COMMISSION_DETAILS, {
    onCompleted(data) {
      setCommissionDetails([data?.getCommissionDetails]);
    },
    variables: {
      month: getPreviousMonthYear().month.toString(),
      year: getPreviousMonthYear().year.toString(),
    },
  });

  useEffect(() => {
    const refetchFunction = async () => {
      if (
        selectedYear.value === currentYear.toString() &&
        selectedMonth.value > currentMonth
      ) {
        return toast.warning("You can't select future month");
      }
      try {
        const res = await refetch({
          month: selectedMonth.value,
          year: selectedYear.value,
        });
        setCommissionDetails([res?.data?.getCommissionDetails]);
      } catch (error) {
        console.log(error);
      }
    };
    refetchFunction();
  }, [selectedMonth, selectedYear]);

  const dateObj = new Date();
  // const [upload_invoice] = useMutation(UPLOAD_INVOICE, {
  //   refetchQueries: [
  //     {
  //       query: GET_INVOICES,
  //       variables: {
  //         page: 1,
  //         limit: 100,
  //       },
  //     },
  //   ],
  // });
  const [requestInvoice, { loading }] = useMutation(REQUEST_INVOICE, {
    refetchQueries: [
      {
        query: GET_INVOICES,
        variables: {
          page: 1,
          limit: 100,
        },
      },
    ],
  });
  const [generateInvoiceModal, setGenerateInvoiceModal] = useState(false);
  const handleCheckboxChange = (index: number) => {
    setSelectedCheckbox(index);
  };

  const curruntMonth = new Date().getMonth();

  const mergeValueInSingleArray = (arr: any) => {
    const arr1: any = [];

    for (let index = 0; index < 12; index++) {
      if (index !== curruntMonth) {
        const element = arr[index];
        if (element.commission?.length) {
          arr1.push(element);
        }
      }
    }
    return arr1;
  };

  const amountWithoutGst = (amount: number) => {
    let AMOUNT_WITHOUT_GST = amount / (1 + 18 / 100);
    let tax = (amount - AMOUNT_WITHOUT_GST).toFixed(2);
    let total = amount.toFixed(2);
    return { AMOUNT_WITHOUT_GST: AMOUNT_WITHOUT_GST.toFixed(2), tax, total };
  };
  const validFileTypes = ["application/pdf"];
  const maxSize = 10 * 1024 * 1024;

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];

    if (file) {
      const file = e.target.files[0];
      if (!validFileTypes.find((type) => type === file.type)) {
        return toast.warning("File must be in PDF format");
      }
      if (file.size > maxSize) {
        return toast.warning("file size is larger than 10MB");
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e?.target?.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // useEffect(() => {
  //   if (
  //     getCommission?.getCommissionDetails?.totalCommission === 0 &&
  //     commissionLoading === false
  //   ) {
  //     console.log("here", getCommission?.getCommissionDetails?.totalCommission);
  //     setSelectedCheckbox(null);
  //   }
  // }, [selectedMonth, selectedYear]);
  // console.log(selectedCheckbox);

  return (
    <>
      <Modal
        className="w-full max-w-7xl"
        open={generateInvoiceModal}
        setOpen={setGenerateInvoiceModal}
      >
        <TaxInvoiceTemplate
          setGenerateInvoiceModal={setGenerateInvoiceModal}
          invoiceDetails={invoiceDetails}
        />
      </Modal>
      <div className=" bg-white rounded-md px-4 py-6 m-4">
        <_Table
          boxPadding={" p-0"}
          minHeight={" h-auto"}
          bgColor=" bg-gray-50"
          searchBox={
            <>
              <p className="text-base text-[#1B163B] font-semibold ml-4">
                Select Period
              </p>
              <div className="flex justify-end items-center w-1/2 space-x-2">
                <div className="w-full">
                  <Select
                    placeholder="Select Month"
                    className="font-normal m-0 capitalize"
                    options={[
                      { label: "January", value: 1 },
                      { label: "February", value: 2 },
                      { label: "March", value: 3 },
                      { label: "April", value: 4 },
                      { label: "May", value: 5 },
                      { label: "June", value: 6 },
                      { label: "July", value: 7 },
                      { label: "August", value: 8 },
                      { label: "September", value: 9 },
                      { label: "October", value: 10 },
                      { label: "November", value: 11 },
                      { label: "December", value: 12 },
                    ].map((month) => {
                      return {
                        label: month.label,
                        value: month.value,
                        isDisabled:
                          selectedYear.value === currentYear.toString() &&
                          month.value > currentMonth,
                      };
                    })}
                    onChange={(e: any) => {
                      setSelectedCheckbox(null);
                      setSelectedMonth({
                        label: e.label.toString(),
                        value: e.value.toString(),
                      });
                    }}
                    components={{
                      DropdownIndicator: CustomDropdownIndicator,
                      IndicatorSeparator: () => null,
                    }}
                    isSearchable={false}
                    styles={{
                      control: (provided: any) => ({
                        ...provided,
                        backgroundColor: "#F6F8FA",
                        border: "1px solid #1B163B",
                        borderRadius: "6px",
                        minHeight: "15px",
                        margin: "0px",
                        color: "#6687FF",
                      }),
                      valueContainer: (provided: any) => ({
                        ...provided,
                        padding: "4px",
                        paddingLeft: "0.5rem",
                        fontSize: "12px",
                      }),
                      input: (provided: any) => ({
                        ...provided,
                        backgroundColor: "transparent",
                        color: "#000",
                        "::placeholder": {
                          backgroundColor: "#YourDesiredColor",
                          opacity: 1,
                        },
                        placeholder: (provided: any) => ({
                          ...provided,
                          color: "red",
                        }),
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        fontSize: "10px",
                      }),
                    }}
                  />
                </div>
                <div className="w-full">
                  <Select
                    placeholder="Select Year"
                    className="font-normal m-0  capitalize"
                    options={[
                      { label: "2024", value: "2024" },
                      { label: "2025", value: "2025" },
                    ].map((status: any) => {
                      return {
                        label: status.label,
                        value: status.value,
                      };
                    })}
                    onChange={(e: any) => {
                      setSelectedCheckbox(null);
                      setSelectedYear({
                        label: e.label.toString(),
                        value: e.value.toString(),
                      });
                    }}
                    components={{
                      DropdownIndicator: CustomDropdownIndicator,
                      IndicatorSeparator: () => null,
                    }}
                    isSearchable={false}
                    styles={{
                      control: (provided: any) => ({
                        ...provided,
                        backgroundColor: "#F6F8FA",
                        border: "1px solid #1B163B",
                        borderRadius: "6px",

                        minHeight: "15px",
                        margin: "0px",
                        color: "#6687FF",
                      }),
                      valueContainer: (provided: any) => ({
                        ...provided,
                        padding: "4px",
                        paddingLeft: "0.5rem",
                        fontSize: "12px",
                      }),
                      input: (provided: any) => ({
                        ...provided,
                        backgroundColor: "transparent",
                        color: "#000",
                        "::placeholder": {
                          backgroundColor: "#YourDesiredColor",
                          opacity: 1,
                        },
                        placeholder: (provided: any) => ({
                          ...provided,
                          color: "red",
                        }),
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        fontSize: "10px",
                      }),
                    }}
                  />
                </div>
              </div>
            </>
          }
          data={[
            ["", "Commission Period", "Commission Amount", "Tax"],
            ...commissionDetails?.map((d: any, index: number) => [
              <div>
                <input
                  checked={
                    d?.totalCommission === 0 || selectedCheckbox === index
                  }
                  disabled={d?.totalCommission === 0}
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      handleCheckboxChange(index);
                      setInvoiceDetails({
                        month: getDateRange(
                          selectedMonth.value,
                          selectedYear.value,
                        ),
                        details: amountWithoutGst(d?.totalCommission),
                      });
                    } else {
                      setSelectedCheckbox(null);
                      setInvoiceDetails({});
                    }
                  }}
                  type="checkbox"
                  id={d?.month}
                />
              </div>,
              <div>
                {getDateRange(selectedMonth.value, selectedYear.value)}
              </div>,
              <div>
                ₹{amountWithoutGst(d.totalCommission).AMOUNT_WITHOUT_GST}
              </div>,
              <div>₹{amountWithoutGst(d.totalCommission).tax}</div>,
            ]),
          ]}
          footer={
            <>
              {selectedCheckbox !== null && (
                <>
                  <div className="p-4 pb-0">
                    <ul className=" space-y-1 my-2 ml-4">
                      <li className="text-black text-sm font-medium">
                        Total Commission Amount: ₹
                        {invoiceDetails?.details?.AMOUNT_WITHOUT_GST}
                      </li>
                      <li className="text-black text-sm font-medium">
                        Total Tax Amount: ₹{invoiceDetails?.details?.tax}
                      </li>
                      <li className="text-black text-sm font-medium">
                        Total Invoice Amount: ₹{invoiceDetails?.details?.total}
                      </li>
                      <li className="text-black text-sm font-medium">
                        Note: TDS, if applicable,will be deducted from final
                        invoice amount
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </>
          }
        />
      </div>
      <>
        {selectedCheckbox !== null && (
          <div className=" bg-white rounded-md space-y-4 px-4 py-6 m-4">
            <>
              {!uploadInvoice ? (
                <>
                  <div className="flex items-center gap-x-3">
                    <button
                      onClick={() => setUploadInvoice(true)}
                      className="px-4 py-2 flex-none  bg-edviron_black rounded-md text-white font-medium text-sm"
                    >
                      Upload Invoice
                    </button>
                    <div>
                      <p className=" text-[11px] font-medium text-gray-500">
                        Use this action if you have created an invoice in your
                        own accounting system and want to upload it.
                      </p>
                      <p className="text-[11px] font-medium text-gray-500">
                        Use this action if you have created an invoice in your
                        own accounting system and want to upload it.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <button
                      onClick={() => setGenerateInvoiceModal(true)}
                      className="px-4 py-2 flex-none  bg-edviron_black rounded-md text-white font-medium text-sm"
                    >
                      Generate Invoice
                    </button>
                    <div>
                      <p className=" text-[11px] font-medium text-gray-500">
                        Use this action if you need help creating an invoice
                        using cashfrees invoice generation utility.
                      </p>
                      <p className="text-[11px] font-medium text-gray-500">
                        Note: Unregistered businessed or GST registered
                        businesses not falling under e-invoicing can use this
                        utility
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  <label className="block">
                    <span className="sr-only">Choose File</span>
                    <input
                      type="file"
                      onChange={(e) => handleImageChange(e)}
                      className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-edviron_black hover:file:bg-violet-100"
                    />
                  </label>

                  <div className="flex mt-4 gap-y-2 flex-col">
                    <label
                      htmlFor="invoiceNo"
                      className=" text-gray-500 font-medium text-[12px]"
                    >
                      Invoice No. *
                    </label>
                    <input
                      className=" rounded-md max-w-md w-full px-4 py-1 focus:outline-none border border-gray-400"
                      type="text"
                      name="invoiceNo"
                      id="invoiceNo"
                      onChange={(e) => setInvoiceNumber(e.target.value)}
                      onKeyDown={preventNegativeValues}
                      onPaste={preventPasteNegative}
                    />

                    <label
                      htmlFor="hsnNo"
                      className=" text-gray-500 font-medium text-[12px]"
                    >
                      HSN/SAC No. *
                    </label>

                    <input
                      className=" rounded-md max-w-md w-full px-4 py-1 focus:outline-none border border-gray-400"
                      type="text"
                      id="hsnNo"
                      onChange={(e) => setHsnNumber(e.target.value)}
                      onKeyDown={preventNegativeValues}
                      onPaste={preventPasteNegative}
                    />
                    <div className=" flex">
                      <button
                        onClick={async () => {
                          try {
                            const res = await requestInvoice({
                              variables: {
                                invoice_date: `${dateObj.getDate()} ${monthsName[dateObj.getMonth()]} ${dateObj.getFullYear()}`,
                                invoice_no: invoiceNumber,
                                hsn: hsnNumber,
                                amount_in_words: amountToWords(
                                  invoiceDetails.details?.total,
                                ),
                                note: "note",
                                duration: invoiceDetails.month,
                                amount_without_gst: parseFloat(
                                  invoiceDetails?.details.AMOUNT_WITHOUT_GST,
                                ),
                                tax: parseFloat(invoiceDetails?.details?.tax),
                                amount: parseFloat(
                                  invoiceDetails.details?.total,
                                ),
                                base64: selectedImage,
                              },
                            });
                            if (res?.data?.requestInvoice) {
                              toast.success(res?.data?.requestInvoice);
                              setOpen(false);
                            }
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                        disabled={
                          !selectedImage || !hsnNumber || !invoiceNumber
                        }
                        className="px-4 py-1.5 flex-none max-w-fit   bg-edviron_black disabled:bg-gray-600 rounded-md text-white font-medium text-sm"
                      >
                        Approve and Send Invoice
                      </button>
                      <button
                        onClick={() => setUploadInvoice(false)}
                        className="px-4 ml-2 py-1.5 flex-none max-w-fit border   border-edviron_black  rounded-md text-black font-medium text-sm"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        )}
      </>
    </>
  );
}

export default SelectCommission;
