import React, { useEffect, useRef, useState } from "react";
import { _Table, RowsPerPageSelect } from "../../../components/Table";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_RECONCILIATION } from "../../../Qurries";
import Pagination from "../../../components/Pagination/Pagination";
import { amountFormat } from "../../../utils/amountFormat";
import { HiMiniXMark } from "react-icons/hi2";
import { endOfDay, startOfDay } from "date-fns";
import Institute from "../Transaction/components/AllFilter/Institute";
import { CustomDropdownIndicator } from "../Settlement/Settlement";
import TransactionDateFilter, {
  formatDate,
} from "../Transaction/components/TransactionDateFilter";
import Select from "react-select";
import { getStartAndEndOfMonth } from "../../../utils/getStartAndEndOfMonth";
function Reconciliation() {
  const [itemsPerRow, setItemsPerRow] = useState({ name: 10 });
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState<any>("");

  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [type, setType] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [transactionData, setTransactionData] = useState<any>([]);
  const [isDateRangeIsSelected, setIsDateRangeIsSelected] = useState(false);
  const [status, setStatus] = useState<any>(null);
  const [schoolId, setSchoolId] = useState<string>("");
  const [selectSchool, setSelectSchool] = useState("");
  const [refetching, setRefetching] = useState(false);
  const [searchFilter, setSearchFilter] = useState<any>("");
  const [reconciliation, setReconciliationData] = useState<any>([]);
  const { startDate, endDate } = getStartAndEndOfMonth();
  const {
    data: reconciliationData,
    loading,
    refetch,
  } = useQuery(GET_RECONCILIATION, {
    onCompleted(data) {
      setReconciliationData(data?.getReconcilation?.reconciliation);
    },
    variables: {
      limit: itemsPerRow.name,
      page: page,
      //startDate: startDate,
      ///endDate: endDate,
    },
  });
  const refetchDataFetch = async ({
    start_date,
    end_date,
    school_id,
  }: {
    start_date?: any;
    end_date?: any;

    school_id?: string | null;
  }) => {
    try {
      setRefetching(true);
      const data = await refetch({
        endDate: end_date,
        startDate: start_date,
        page: page,
        limit: itemsPerRow.name,
        school_id,
      });

      if (data?.data?.getReconcilation?.reconciliation) {
        setRefetching(false);
        setReconciliationData(data?.data?.getReconcilation?.reconciliation);
      }
    } catch (error) {}
  };

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    refetchDataFetch({
      start_date: isDateRangeIsSelected
        ? formatDate(selectedRange.startDate)
        : null,
      end_date: isDateRangeIsSelected
        ? formatDate(selectedRange.endDate)
        : null,
      school_id: schoolId === "" ? null : schoolId,
    });
  }, [schoolId, itemsPerRow, page]);

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  console.log(isDateRangeIsSelected);

  return (
    <div>
      <_Table
        heading="Reconciliation"
        loading={loading || refetching}
        searchBox={
          <div className="w-full ">
            <div className="flex xl:!flex-row flex-col justify-end gap-2  w-full xl:items-center items-start mb-2">
              <div className="flex justify-end items-center flex-1 w-full max-w-[34rem]">
                <TransactionDateFilter
                  setType={setDateRange}
                  type={dateRange}
                  refetch={() => {
                    refetchDataFetch({
                      start_date: formatDate(selectedRange.startDate),
                      end_date: formatDate(selectedRange.endDate),
                      school_id: schoolId === "" ? null : schoolId,
                    });
                  }}
                  selectedRange={selectedRange}
                  setSelectedRange={setSelectedRange}
                  setIsDateRangeIsSelected={setIsDateRangeIsSelected}
                />

                <div className="w-full ml-2">
                  <Institute
                    setSelectSchool={setSelectSchool}
                    setSchoolId={setSchoolId}
                  />
                </div>
              </div>
            </div>
            <div>
              <RowsPerPageSelect
                setItemsPerRow={setItemsPerRow}
                itemsPerRow={itemsPerRow}
                className=" justify-start"
              />
            </div>
            <div className="flex items-center">
              {dateRange !== "" && (
                <div className=" text-sm m-2  max-w-fit ">
                  <button
                    onClick={async () => {
                      setSelectedRange({
                        startDate: startOfDay(new Date()),
                        endDate: endOfDay(new Date()),
                        key: "selection",
                      });
                      setDateRange("");
                      setIsDateRangeIsSelected(false);
                      if (schoolId === "") {
                        refetchDataFetch({});
                      } else {
                        refetchDataFetch({
                          school_id: schoolId,
                        });
                      }
                    }}
                    className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                  >
                    {dateRange} <HiMiniXMark className=" text-lg ml-1" />
                  </button>
                </div>
              )}
              {selectSchool !== "" && (
                <div className=" text-sm m-2  max-w-fit ">
                  <button
                    onClick={() => {
                      if (isDateRangeIsSelected) {
                        refetchDataFetch({
                          start_date: isDateRangeIsSelected
                            ? formatDate(selectedRange.startDate)
                            : startDate,
                          end_date: isDateRangeIsSelected
                            ? formatDate(selectedRange.endDate)
                            : endDate,
                        });
                        setSelectSchool("");
                        setSchoolId("");
                      } else {
                        setSelectSchool("");
                        setSchoolId("");
                      }
                    }}
                    className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                  >
                    {selectSchool} <HiMiniXMark className=" text-lg ml-1" />
                  </button>
                </div>
              )}
            </div>
          </div>
        }
        data={[
          [
            "Sr.No.",
            "From",
            "Till",
            "Merchant",
            "Settlement Date",
            "Total Transaction",
            "Total Settlement",
            "Difference Amount",
            "Settlement Mismatch",
          ],
          ...(reconciliation
            .map((item: any, index: number) => {
              return {
                ...item,
                serialNumber: (page - 1) * itemsPerRow.name + 1 + index,
              };
            })
            .map((item: any) => [
              <span>{item.serialNumber}</span>,
              <Link to="/payments/reconciliation-receipt" state={item}>
                {new Date(item.fromDate).toLocaleString("hi")}
              </Link>,
              <Link to="/payments/reconciliation-receipt" state={item}>
                {new Date(item.tillDate).toLocaleString("hi")}
              </Link>,
              <Link to="/payments/reconciliation-receipt" state={item}>
                {item.school_name}
              </Link>,
              <Link to="/payments/reconciliation-receipt" state={item}>
                {new Date(item.settlementDate).toLocaleString("hi")}
              </Link>,
              <Link to="/payments/reconciliation-receipt" state={item}>
                {amountFormat(item.totaltransactionAmount)}
              </Link>,
              <Link to="/payments/reconciliation-receipt" state={item}>
                {amountFormat(item.settlementAmount)}
              </Link>,
              <Link to="/payments/reconciliation-receipt" state={item}>
                {amountFormat(item.merchantAdjustment)}
              </Link>,
              <Link
                to="/payments/reconciliation-receipt"
                state={item}
                className={
                  "font-bold " +
                  (item.merchantAdjustment !== 0
                    ? "text-red-500"
                    : "text-green-500")
                }
              >
                {item.merchantAdjustment !== 0 ? "Yes" : "No"}
              </Link>,
            ]) || []),
        ]}
        footer={
          <div className="flex justify-center items-center">
            <Pagination
              currentPage={page}
              totalPages={Math.ceil(
                reconciliationData?.getReconcilation?.totalPages,
              )}
              onPageChange={handlePageChange}
            />
          </div>
        }
      />
    </div>
  );
}

export default Reconciliation;
